/* eslint-disable */
// todo: remove jquery and enable eslint
$(document).ready(function () {
    var $stickyMenuWrapper = $('.sticky-menu-wrapper');
    var footerHeight = $('.gj-footer').outerHeight();
    var pageMarginBottom= $('.as-10-90-page').css('margin-bottom');
    var pageMargin = pageMarginBottom ? pageMarginBottom.replace('px', '') : null;
    var stickyOffsetBottom = footerHeight + parseInt(pageMargin);

    $('.sticky-menu-desktop').affix({
        offset: {
            top: function(){
                return $stickyMenuWrapper.offset().top;
            },
            bottom: (stickyOffsetBottom + 40)
        }
    });

    $('.sticky-menu-mobile').affix({
        offset: {
            top: function(){
                return $stickyMenuWrapper.offset().top;
            }
        }
    });

    $("body").scrollspy({target: ".scrollspy", offset: 100}).css("position", "relative");
    $(".main-article").css("margin-top", "20px");

    $(document).on("click", ".sticky-menu-mobile .menu-item", function (event) {
        event.preventDefault();
        var $link = $(event.currentTarget.hash);
        var $sticky = $(".sticky-menu-mobile");
        var height = 0;

        if ($sticky.hasClass('affix-top')) height = $sticky.height();

        $('html, body').animate({
            scrollTop: $link.offset().top - height - 50
        }, 500);
    });

    $(document).on("click", ".sticky-menu-desktop .menu-item", function (event) {
        event.preventDefault();
        var $link = $(event.currentTarget.hash);

        $('html, body').animate({
            scrollTop: $link.offset().top
        }, 500);
    });

});

/* eslint-enable */
