import closest from 'closest';
import delegate from 'delegate';

document.addEventListener('DOMContentLoaded', () => {
    delegate(
        document.body,
        '[data-dismiss="alert"]',
        'click',
        event => {
            const alert = closest(event.delegateTarget, '.intra-alert');
            alert.classList.add('hidden');
        },
        false
    );
});
