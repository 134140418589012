import './hopp-article/hopp-artilce.es6';

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.menuItems ul li')[0].classList.add('active');
    document.querySelectorAll('.tabs li')[0].classList.add('active');
    document.querySelectorAll('.tab-content div')[0].classList.add('active');
    document.getElementsByClassName('iconWrapper')[0].classList.add('gj-icon');
    document.getElementsByClassName('iconWrapper')[0].classList.add('gj-icon-menu-wrapper');
    document.getElementsByClassName('iconMenu')[0].classList.add('gj-icon');
    document.getElementsByClassName('iconMenu')[0].classList.add('gj-icon-menu');
    Array.from(document.querySelectorAll('.menuItems ul')).forEach(menuItem => {
        const activeItems = menuItem.getElementsByClassName('active');
        if (activeItems.length > 1) {
            activeItems[1].getElementsByClassName('iconWrapper')[0].classList.add('gj-icon');
            activeItems[1].getElementsByClassName('iconWrapper')[0].classList.add('gj-icon-menu-wrapper');
            activeItems[1].getElementsByClassName('iconMenu')[0].classList.add('gj-icon');
            activeItems[1].getElementsByClassName('iconMenu')[0].classList.add('gj-icon-menu');
            document.querySelectorAll('.menuItems ul li')[0].classList.remove('active');
            document.querySelectorAll('.tab-content div')[0].classList.remove('active');
            document.querySelectorAll('.tabs li')[0].classList.remove('active');
            document.getElementsByClassName('iconWrapper')[0].classList.remove('gj-icon');
            document.getElementsByClassName('iconWrapper')[0].classList.remove('gj-icon-menu-wrapper');
            document.getElementsByClassName('iconMenu')[0].classList.remove('gj-icon');
            document.getElementsByClassName('iconMenu')[0].classList.remove('gj-icon-menu');
        }

        Array.from(activeItems).forEach(activeItem => {
            Array.from(activeItem.getElementsByTagName('a')).forEach(activeClasses => {
                activeClasses.removeAttribute('href');
                activeClasses.setAttribute('data-toggle', 'collapse');
                activeClasses.setAttribute('data-target', '#main-menu');
                activeClasses.setAttribute('data-webtrends-type', 'header_cta');
                activeClasses.setAttribute('data-webtrends-name', 'menu_toggle');
                activeClasses.classList.toggle('collapsed');
                activeItem.addEventListener('click', e => {
                    e.preventDefault();
                    activeItem
                        .getElementsByTagName('a')[0]
                        .getElementsByClassName('iconWrapper')[0]
                        .classList.toggle('active');
                });
            });

            document.querySelector('.main-menu-collapse-button').addEventListener('click', e => {
                e.preventDefault();
                activeItem
                    .getElementsByTagName('a')[0]
                    .getElementsByClassName('iconWrapper')[0]
                    .classList.remove('active');
            });
        });
    });
});
