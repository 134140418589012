import Cookies from 'js-cookie';
import closest from 'closest';

const systemMessages = (() => {
    const init = () => {
        Array.from(document.querySelectorAll('.common-application-warning .close-button')).forEach(button => {
            button.addEventListener('click', event => {
                event.preventDefault();
                const applicationWarning = closest(button, '.common-application-warning');
                applicationWarning.classList.add('hidden');
                const expiresDays = 30;
                const systemMessageId = button.getAttribute('data-system-message-id');
                Cookies.set(`common-system-message-${systemMessageId}`, systemMessageId, { expiresDays });
            });
        });
    };
    return {
        init
    };
})();

document.addEventListener('DOMContentLoaded', () => {
    systemMessages.init();
});
